<template>
    <div class="content_container">
        <h1>SUBMISSION SUCCESS!</h1>
        <h1>
            Thank you! Your rental form was successfully submitted!
        </h1>
        <div class="buttons">
            <router-link class="btn" to="/rental-submission">
                Back to Form
            </router-link>
            <router-link class="btn" to="/">
                Go Back Home
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "SubmissionSuccess",
    metaInfo: {
        title: 'Form Submission Success',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
}
</script>

<style lang="scss" scoped>
.content_container{
    text-align: center;
}
h1{
    text-align: center;
    font-family: $secondaryFont;
    font-size: 2.5rem;
    margin-bottom: 0;
    padding-top: 80px;
}
hr{
    width: 300px;
    margin: 32px auto;
    opacity: 0.4;
}

.buttons{
    margin: 100px 0;
    display: flex;
    gap: 0 20px;
    justify-content: center;
}
</style>
